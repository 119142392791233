<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Pasien Teratas</b> {{ clinic_name }}
      </div>
      <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/patient/add')"
        v-if="manipulateBtn == true"
        >Tambah</b-button
      > -->
      <!-- <b-button
        squared
        variant="outline-primary"
        class="mx-1"
        @click="$router.push('/patient/import')"
      >
        <i class="fas fa-file-excel pr-1"></i>
        Unggah Excel</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <!-- Filter -->
            <div class="row justify-content-start mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('yesterday')"
                  v-bind:class="{
                    'btn-info': btnActive.yesterday,
                    'btn-outline-info': btnActive.yesterday == false,
                  }"
                >
                  Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('week')"
                  v-bind:class="{
                    'btn-info': btnActive.week,
                    'btn-outline-info': btnActive.week == false,
                  }"
                >
                  Minggu Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <!-- <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button> -->
                <!-- <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  1 Tahun Mendatang
                </button> -->
                <!-- <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button> -->
              </div>
              <!-- <div class="col-md-3 col-12">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien Berkunjung"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div> -->
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3 mb-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <b-tabs content-class="mt-3 pt-3">
              <b-tab title="Total Tansaksi Teratas" active>
                <b-table striped hover :items="items" :fields="fields">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(number)="data">
                    <span>{{ data.item.index + 1 }}</span>
                  </template>
                  <template #cell(name)="data">
                    <span>{{
                      data.item.name + " (" + data.item.number_patient + ")"
                    }} <i class="fas fa-trophy text-warning" v-if="data.item.index == 0"></i></span>
                  </template>
                  <template #cell(amount_money)="data">
                    <span>{{
                      parseInt(data.item.amount_money).toLocaleString("id-ID")
                    }}</span>
                  </template>

                  <!-- <template #cell(actions)="data">
                <b-button v-if="data.item.is_receipt_message_birthday == null"
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Kirim Whatsapp"
                  @click="showModalPreview(data.item)"
                >
                  <i class="fab fa-whatsapp px-0"></i>
                </b-button>
              </template> -->
                </b-table>
              </b-tab>
              <b-tab title="Jumlah Tansaksi Teratas"
                ><b-table striped hover :items="itemsTransaction" :fields="fieldsTransacton">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(number)="data">
                    <span>{{ data.item.index + 1 }}</span>
                  </template>
                  <template #cell(name)="data">
                    <span> {{
                      data.item.name + " (" + data.item.number_patient + ")"
                    }} <i class="fas fa-trophy text-warning" v-if="data.item.index == 0"></i></span>
                  </template>
                  <!-- <template #cell(amount_money)="data">
                    <span>{{
                      parseInt(data.item.amount_money).toLocaleString("id-ID")
                    }}</span>
                  </template> -->

                  <!-- <template #cell(actions)="data">
                <b-button v-if="data.item.is_receipt_message_birthday == null"
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Kirim Whatsapp"
                  @click="showModalPreview(data.item)"
                >
                  <i class="fab fa-whatsapp px-0"></i>
                </b-button>
              </template> -->
                </b-table></b-tab
              >
            </b-tabs>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal WA Setting -->
    <b-modal id="modal-wa-settings" hide-footer>
      <template #modal-title> Pengaturan Pesan Ulang Tahun </template>

      <b-form @submit.stop.prevent="editWaSetting($event)">
        <!-- Input Description -->
        <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Ucapan Selamat : </label>
          <b-form-textarea
            id="input-wa_birthday_message"
            v-model="formWaSetting.wa_birthday_message"
            placeholder="Ucapan Selamat"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{
            errorWaSetting.wa_birthday_message
          }}</small>
        </b-form-group>

        <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Kirim Pesan Otomatis :</label>
          <div class="row">
            <div class="col">
              <b-form-checkbox
                switch
                class="mr-n2"
                v-model="formWaSetting.is_wa_auto_send"
              >
                <span>{{
                  formWaSetting.is_wa_auto_send ? "Aktif" : "Tidak Aktif"
                }}</span>
              </b-form-checkbox>
            </div>
            <span>{{ errorWaSetting.is_wa_auto_send }}</span>
          </div>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" class="mr-1">
            Terapkan
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="$bvModal.hide('modal-wa-settings')"
          >
            Tutup
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Preview -->
    <b-modal id="modal-preview-message" hide-footer>
      <template #modal-title> Kirim Pesan Ulang Tahun </template>

      <b-form @submit.stop.prevent="sendMessage($event)">
        <!-- Input Description -->
        <b-form-group id="input-group-wa_birthday_message">
          <label for="input-wa_birthday_message">Ucapan Selamat : </label>
          <b-form-textarea
            id="input-wa_birthday_message"
            v-model="formSendMessage.wa_birthday_message"
            placeholder="Ucapan Selamat"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{
            errorSendMessage.wa_birthday_message
          }}</small>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="primary" type="submit" class="mr-1">
            Kirim
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="$bvModal.hide('modal-preview-message')"
          >
            Batal
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    Card,
  },

  data() {
    return {
      filter: {
        name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        limit: 20,
      },

      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 1,
      items: [],
      itemsTransaction: [],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Pasien",
          sortable: true,
        },
        {
          key: "amount_money",
          label: "Total (Rp)",
          sortable: true,
          class: "text-right",
        },
      
      ],
      fieldsTransacton: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Pasien",
          sortable: true,
        },
        {
          key: "amount_transaction",
          label: "Jumlah",
          sortable: true,
          class: "text-right",
        },
      
      ],
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        yesterday: false,
        week: false,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // Other
      clinic_name: "Medhop",
      formWaSetting: {
        is_wa_auto_send: false,
        wa_birthday_message: "",
      },
      errorWaSetting: {
        is_wa_auto_send: "",
        wa_birthday_message: "",
      },
      formSendMessage: {
        wa_birthday_message: "",
        mobile_phone: "",
        patient_id: "",
      },
      errorSendMessage: {
        wa_birthday_message: "",
        mobile_phone: "",
        patient_id: "",
      },
      manipulateBtn: true,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&limit=${this.filter.limit}`;
      let response = await module.paginate(
        `patient-spending-money`,
        `?${filterParams}`
      );
      // let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      // let a, b;
      // b = this.perPage * (this.currentPage - 1) + 1;
      // for (a = 0; a < response.data.length; a++) {
      //   response.data[a].number = b + a;
      // }
      this.items = response.data.items.money;
      this.itemsTransaction = response.data.items.transaction;
    },

    async settingWa() {
      this.formWaSetting = await module.get("settings/" + 1);
      // If Data Not Found
      if (this.formWaSetting == null) {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal dimuat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.formWaSetting.is_wa_auto_send
          ? (this.formWaSetting.is_wa_auto_send = true)
          : (this.formWaSetting.is_wa_auto_send = false);
        this.formWaSetting["_method"] = "put";
        this.$bvModal.show("modal-wa-settings");
      }
    },

    async editWaSetting(evt) {
      evt.preventDefault();
      this.formWaSetting.is_wa_auto_send
        ? (this.formWaSetting.is_wa_auto_send = 1)
        : (this.formWaSetting.is_wa_auto_send = 0);
      let response = await module.submit(this.formWaSetting, "settings/1");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-wa-settings");
        // this.pagination()
      }
    },

    async showModalPreview(item) {
      let response = await module.get("settings/" + 1);
      // If Data Not Found
      if (response == null) {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal dimuat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.formSendMessage.mobile_phone = item.mobile_phone;
        this.formSendMessage.patient_id = item.id;
        this.formSendMessage.wa_birthday_message = response.wa_birthday_message;
        console.log("formsendmes", this.formSendMessage);
        this.$bvModal.show("modal-preview-message");
      }
    },

    async sendMessage(evt) {
      evt.preventDefault();
      let response = await module.submit(
        this.formSendMessage,
        "patients-send-birthday-message"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil kirim pesan",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-preview-message");
        this.pagination();
      }
    },

    filterByName() {
      this.pagination();
    },

    // filter
    resetFilter() {
      this.filter.name = "";
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.yesterday = false;
        this.btnActive.week = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "yesterday") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "days")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.yesterday = true;
        this.btnActive.week = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "week") {
        // set time
        this.filter.start_date = moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        // console.log(moment().add(30, 'days').format("YYYY-MM-DD HH:mm:ss"))
        // if(moment().add(30, 'days').format("YYYY") != moment().format("YYYY")){
        //   this.filter.end_date = moment().endOf("year").endOf('day').format("YYYY-MM-DD HH:mm:ss")
        // }else{
        //   this.filter.end_date = moment().add(30, 'days').endOf('day').format("YYYY-MM-DD HH:mm:ss")
        // }

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.yesterday = false;
        this.btnActive.week = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .subtract(30, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        // console.log(moment().add(30, 'days').format("YYYY-MM-DD HH:mm:ss"))
        // if(moment().add(30, 'days').format("YYYY") != moment().format("YYYY")){
        //   this.filter.end_date = moment().endOf("year").endOf('day').format("YYYY-MM-DD HH:mm:ss")
        // }else{
        //   this.filter.end_date = moment().add(30, 'days').endOf('day').format("YYYY-MM-DD HH:mm:ss")
        // }

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.yesterday = false;
        this.btnActive.week = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("patients-birthday/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },
    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");
        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.setActiveMenu();
    // set title
    this.clinic_name =
      window.configuration == null
        ? "Medhop"
        : window.configuration.clinic_name;
    this.pagination();
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien Ultah" },
      { title: "Daftar Pasien Ultah" },
    ]);
  },
};
</script>
